$primary_font: 'Mulish', sans-serif;
$desktop-padding-bottom: 90px;
$mobile-padding-bottom: 48px;
$heading-message: 600 14px $primary_font;
$body-message: 500 14px $primary_font;
$calendar-heading-message: 600 13px $primary_font;
$SearchWidth: 63%;

@mixin card-label-responsive-font {
    @media(max-width:1100px) {
        font: 600 11px $primary_font;
    }
}

@mixin sideBySideCol {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

$variable-styles: (
    flex: (display: flex,
        align-items: center,
        justify-content: space-between,
        flex-direction:column,
        flex-wrap: wrap,
        white-space:nowrap,
        text-overflow:ellipsis,
        overflow:hidden,
        text-decoration:underline,
        cursor:pointer,
        word-wrap:break-word),
    block: (display: block,
    ),
    inline-block: (display:inline-block,
    ),
    color: (color:#ec6608,
    )
);

@mixin apply-styles($type, $props...) {
    @each $prop in $props {
        $next-index: index($props, $prop) + 1;

        @if $next-index <=length($props) {
            $value: nth($props, $next-index);
            $important: false;

            @if type-of($value)=="string" and str-index($value, "!important") {
                $value: str-slice($value, 1, str-length($value) - 10);
                $important: true;
            }

            @if map-has-key($variable-styles, $type) and map-has-key(map-get($variable-styles, $type), $prop) {
                #{$prop}: map-get(map-get($variable-styles, $type), $prop);
            }

            @else if index(("display", "align-items", "justify-content", "width", "height", "background",
                    "background-color", "border", "border-radius", "border-color", "transition",
                    "position", "z-index", "box-shadow", "left", "top", "right", "bottom",
                    "flex-wrap", "overflow", "color", "cursor"), $prop) {
                #{$prop}: $value;

                @if $important {
                    #{$prop}: $value !important;
                }
            }

            @else if str-index("" + $prop, "margin") or str-index("" + $prop, "padding") {
                #{$prop}: $value;

                @if $important {
                    #{$prop}: $value !important;
                }
            }

            // @if str-index("" + $prop, "margin") != null or str-index("" + $prop, "padding") != null {
            //     #{$prop}: $value;

            //     @if $important {
            //         #{$prop}: $value !important;
            //     }
            // }
        }
    }
}

@function font($weight, $size, $transform: null) {
    @return if($transform, #{$weight} #{$size} $primary_font + (text-transform $transform), #{$weight} #{$size} $primary_font);
}

@function margin($top: 0px, $right: 0px, $bottom: 0px, $left: 0px) {
    @return #{$top} #{$right} #{$bottom} #{$left};
}

@function padding($top: 0px, $right: 0px, $bottom: 0px, $left: 0px) {
    @return #{$top} #{$right} #{$bottom} #{$left};
}

@function borderRadius($top: 0px, $right: 0px, $bottom: 0px, $left: 0px) {
    @return #{$top} #{$right} #{$bottom} #{$left};
}

@function gap($size, $scale: 1, $unit: px) {
    $calculated-gap: $size * $scale;
    @return #{$calculated-gap}#{$unit};
}

@mixin font10($weight, $size) {
    font: font($weight, $size);
}

@mixin matchFoundColor {
    color: white;
    background-color: #ec6608;
    display: flex;
    align-items: center;
    border-radius: 50%;
    width: 28px;
    justify-content: center;
    padding: 7px;
}

@mixin paymentBlockDetailRow {
    display: flex;
    justify-content: center;
    width: 100%;
}

@mixin matDivider {
    display: flex;
    justify-content: center;
    margin: 13px 0px;
}

@mixin ClearAllButton {
    background: none;
    border: none;
    color: #ec6608;
    cursor: pointer;
    font: font(700, 14px);

    &:hover {
        text-decoration: underline;
    }

    &.visible {
        visibility: visible;
    }
}

@mixin paymentBorderStyle {
    border-right: 1px solid;
    padding-right: 10px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}



/*********** My Policy & Other Button UI Design ****************/

// .cardsHover {
//     display:none;
//     transition: border 1s ease-in-out;

//     &:hover {
//         display: flex;
//         transition: transform 0.4s ease, box-shadow 0.4s ease;
//         // border: 0.2px solid #ec6608;
//     }
// }

.button-container {
    opacity: 0;
    visibility: hidden;
    transform: translateY(15px);
    transition: opacity 10s ease-in-out, transform 10s ease-in-out, visibility 10s ease-in-out;
}

.cards:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
    border: 0.5px solid #ec6608;
}

.cards.show-buttons .button-container {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: opacity 4s ease-in-out, transform 4s ease-in-out, visibility 4s ease-in-out;
}


p {
    font: font(500, 13px);

    @media(max-width:1100px) {
        font: font(600, 10px);
    }
}


.desktopScreen {
    display: flex;
}

.mobileScreen {
    display: none;
}


@media (max-width: 768px) {
    .desktopScreen {
        @include apply-styles(flex, display, none);
        display: none;
    }

    .mobileScreen {
        @include apply-styles(flex, display, flex, padding, 12px 0px 0px, gap, 12px);
        gap: gap(12);
        display: flex;
        padding: 12px 0px 0px 0px;

        .labelBtn img {
            @include apply-styles(flex, border, 1px solid #ec6608, border-radius, 12px, padding, 0, margin, 0);
            padding: 0;
            margin: 0;
        }
    }
}


.btnCards {
    @include apply-styles(flex, padding, 12px);
    padding: 12px;

    .btn {
        @include apply-styles(flex, padding, 16px, background, #fff 0% 0% no-repeat padding-box, border, 1px solid #ec6608, border-radius, 12px, height, 48px, box-shadow, none !important);
        padding: 16px;

        @media(max-width:1100px) {
            @include apply-styles(flex, height, 40px, border-radius, 8px);
        }
    }

    .addToPayPadding {
        @include apply-styles(flex, padding, 4px 12px 4px 4px !important);
        padding: 4px 12px 4px 4px !important;

        @media(max-width:1100px) {
            @include apply-styles(flex, padding, 0px 12px 0px 0px !important);
            padding: 0px 12px 0px 0px !important
        }
    }
}

.labelBtn {
    @include apply-styles(flex, padding, 0);
}

mat-label {
    font-weight: 600;
}

.failed {
    @include apply-styles(flex, color, #d70d0d !important);
}

.labelName {
    font: font(600, 11px);
    @include apply-styles(flex, color, #00305b);
}

/**** Font Design  ****/
::ng-deep .mat-mdc-checkbox label {
    font-weight: 600;
}

::ng-deep .mat-mdc-raised-button {
    box-shadow: none;
}

::ng-deep .mdc-button__label {
    letter-spacing: 0;
    font: font(600, 13px);
    @include apply-styles(flex, display, flex, align-items, center);

    @media(max-width:575px) {
        font: font(600, 11px);
        padding: 7px;

    }
}

.noRecordImg {
    @include apply-styles(flex, width, 240px !important);
}

.NoRecordFoundData {
    height: calc(100vh - 20vh);
    @include apply-styles(flex);

    display: grid;
    justify-content: center;
    align-content: center;
    margin: 32px 0px;

    @media (max-width: 1100px) {
        height: calc(100vh - 40vh);
    }
}

.noDataFoundErrorSection {
    @include apply-styles(flex, display, flex, align-items, center, height, 85vh);
    justify-content: center;

    .noDataFoundImage {
        // width: 35%;
        @include apply-styles(flex, display, flex, width, 35%,height, 75vh); 

        @media(max-width:768px) {
            @include apply-styles(flex, display, flex, width, 70% , height, 75vh, padding, 20px 20px 20px 20px);
        }

        @media(max-width:575px) {
            // width: 100%;
            @include apply-styles(flex, display, flex, width, 100% , height, 75vh, padding, 12px 12px 12px 12px);
        }

    }
}

.paraMessage {
    font: $heading-message;
}

.messagePara {
    font: $body-message;
}

.cards {
    @include apply-styles(flex, padding, 16px 16px 0px, margin-bottom, 12px, border-radius, 12px);
    transition: transform 0.4s ease, box-shadow 0.4s ease;
    padding: 16px 16px 0px;
    background: rgb(255 255 255 / 93%);
    backdrop-filter: blur(16px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    @media (max-width: 800px) {
        @include apply-styles(flex, padding, 12px);
        padding: 12px;
        background: rgb(255 255 255 / 93%);
        backdrop-filter: blur(16px);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    &:hover {
        transition: transform 0.4s ease, box-shadow 0.4s ease;
    }

    .preProsalRow {
        @include apply-styles(flex, display, flex);
    }
}