@import '../../../../../assets/scss/transaction-dashboard.scss';

.marginTopContainer {
    @include apply-styles(flex, margin-top, 56px);

    @media(max-width:1100px) {
        @include apply-styles(flex, margin-top, 53px);
        max-width: 100%!important;
    }
}

/****** Skeleton Loader  ******/
.skeletonLoader {
    animation: loader 2s infinite linear;
    background: #dedfe1;
    background-image: -webkit-linear-gradient(to right, #dedfe1 0%, #f2f3f5 20%, #dedfe1 40%, #dedfe1 100%);
    background-image: linear-gradient(to right, #dedfe1 0%, #f2f3f5 20%, #dedfe1 40%, #dedfe1 100%);
    background-repeat: no-repeat;

    @include apply-styles(flex, height, 45px, margin-bottom, 12px);
    // margin-bottom: 12px;

    &:last-child {
        @include apply-styles(margin-bottom, 0px);
        // margin-bottom: 0px;
    }
}

@keyframes loader {
    0% {
        background-position: -100px;
    }

    100% {
        background-position: 800px;
    }
}

.skeletonCard {
    @include apply-styles(flex, padding, 16px, margin-bottom, 12px, border-radius, 16px !important);
    // padding: 16px;
    // margin-bottom: 12px;

    &:last-child {
        @include apply-styles(flex, margin-bottom, 56px);
        // margin-bottom: 56px;
    }
}

.gap {
    @include apply-styles (flex, gap, 10px);
}

.input-group__input {
    font: font(500, 12px) !important;
    @include apply-styles(flex, color, #000000); 
}

.disableProductName {
    border: 1px solid #e0e0e0!important;
    background-color: #eeeeee !important;
    color: #9E9E9E!important;
}

.transactionHeading {
    @include apply-styles(flex, display, flex, align-items, center, height, 50px);

    @media(max-width: 1100px) {
        @include apply-styles(flex,
            background, #ffffff,
            position, fixed,
            z-index, 3,
            box-shadow, 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 -5px 12px 0 rgba(0, 0, 0, 0.1),
            left, 0,
            height, 48px);
    }

    .displayFlex {
        @include apply-styles(flex, gap, 12px !important);

        @media(max-width: 1100px) {
            @include apply-styles(flex, display, flex);
            align-items: center;
            gap: gap(12);

            &:nth-of-type(2) {
                gap: gap(20);
            }
        }

        &:first-of-type {
            span {

                img,
                .headerImg {
                    width: 32px;
                    height: 32px;
                }
            }
        }

        &:nth-of-type(2) {
            span {

                img,
                .headerImg {
                    width: 20px;
                    height: 20px;
                }
            }
        }

        .newTitle {
            @include apply-styles(flex, display, flex);
            align-items: center;

            h3 {
                font: font(700, 14px);
            }
        }
    }

    .newTitle {
        @include apply-styles(flex, display, flex, align-items);
    }
}

/***** Search Bar UI Design ******/
$padding-default: 12px;
$padding-small: 14px;

.inputSearchField {
    @include apply-styles(flex, display, flex, align-items, center, width, 100%, height, 48px, background-color, #ffffff, border, 1px solid #cccccc, border-radius, 12px, transition, border-color 0.3s ease);

    padding: 0 #{$padding-default};

    @media(max-width:1100px) {
        @include apply-styles(flex, border, 0px, border-radius, 0px, box-shadow, 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 -5px 12px 0 rgba(0, 0, 0, 0.1), z-index, 3, position, fixed, left, 0);

        @media(max-width: 575px) {
            padding: 0 #{$padding-small};
        }
    }

    .resultFoundAndCloseButton {
        @include apply-styles(flex, display, flex);
        gap: gap(12);

        .resultFound {
            font: font(600, 13px);
            @include matchFoundColor;

            p {
                font: font(600, 11px);
                margin: margin(0);
            }

            @media (max-width: 575px) {
                font: font(600, 10px);
                padding: padding(8px, 8px, 8px, 8px);
                border-radius: borderRadius(8px, 8px, 8px, 8px);
            }
        }
    }

    &:focus-within {
        border-color: #EC6608 !important;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 -5px 12px 0 rgba(0, 0, 0, 0.1);

    }

    .searchIcon {
        margin-right: 8px;

        img {
            @include apply-styles(flex, width, 20px, height, 20px);
        }
    }

    .inputSearch {
        @include apply-styles(flex, border, none, outline, none, width, 100%, height, 100%, font, font(600, 12px), color, #333, padding, 0, background, transparent);
        padding: padding(0px);

        @include font10(600, 13px);

        &::placeholder {
            color: rgb(34, 33, 33) !important;
            font: font(700, 12px) !important;
        }
    }
}


.chipsContainer {
    @include apply-styles(flex, display, flex, overflow-x, auto, white-space, nowrap, flex-wrap, wrap);
    gap: gap(12);

    @media screen and (min-width: 576px) and (max-width: 1100px) {
        @include apply-styles(flex, position, fixed, margin-top, 48px, display, flex, z-index, 2, background, #f7f3f2);
        // margin-top: 48px;
        gap: gap(10);
        overflow: overlay;
        left: 0;
        flex-wrap: nowrap;

        @media screen and (min-width: 800px) {
            justify-content: center;
        }
    }

    @media (max-width: 575px) {
        @include apply-styles(flex, position, fixed, margin-top, 48px, left, 0, z-index, 2, background, #f7f3f2);
        // margin-top: 48px;
        gap: gap(10);
        flex-wrap: nowrap;
        overflow: overlay;
    }

    .chip {
        @include apply-styles(flex, display, inline-flex, align-items, center, justify-content, space-between,
            height, 48px, padding, 0 16px, border, 1px solid #ccc, border-radius, 12px,
            background-color, #fff, cursor, pointer, transition, border-color 0.3s ease,
            background-color 0.3s ease, color, #000000, flex-shrink, 0);
        // padding: 0px 16px;

        font: font(600, 12px);

        @media (max-width: 575px) {
            font: font(600, 11px);
        }

        @media (min-width: 576px) and (max-width: 1024px) {
            font: font(600, 12px);
        }

        @media (max-width: 1100px) {
            @include apply-styles(flex, padding, 10px, margin-top, 10px, height, 40px);
            // padding: 10px;
            // margin-top: 10px;
        }

        &.active {
            border-color: #ec6608;
            background-color: white;
            color: #EC6608;
            font: font(700, 12px);

            @media (max-width: 575px) {
                font: font(800, 11px);
            }

            @media (min-width: 576px) and (max-width: 1024px) {
                font: font(700, 12px);
            }
        }
    }


    span {
        @include apply-styles(flex, margin-left, 8px, font-weight, bold, color, inherit);
        // margin-left: 8px;
    }
}



.column1 {
    @include apply-styles(flex, position, fixed, left, 12px, width, 23%, height, 100vh);
    padding: padding(24px, 24px, 24px, 16px);
    overflow-y: auto;

    @media (max-width: 1100px) {
        @include apply-styles(flex, height, fit-content);
    }

    .height1200 {
        @include apply-styles(flex, height, auto, padding-bottom, 40px);
        // padding-bottom: 40px;
    }

    .input-group__label {
        background: none !important;
    }

    .matDivider {
        @include matDivider;
    }

    .productDetailandCalendar {
        @include apply-styles(flex, margin-bottom, 12px);
        // margin-bottom: 12px;
    }

    .searchBox {
        @include apply-styles(flex, display, flex, justify-content, center, align-items, center, width, 100%, padding, 0px 0px 0px 16px);
        // padding: padding(0px, 0px, 0px, 16px);
    }

    .filterCard {
        @include apply-styles(flex, border, 1px solid #ccc, border-radius, 12px, background-color, #fff);

        .filterHeader {
            @include apply-styles(flex, display, flex, justify-content, space-between, background, #f1f1f1, padding, 16px, border-radius, 14px 14px 0px 0px);
            // padding: 16px;

            h4 {
                @include apply-styles(flex,margin, 0xp 0px 0px 0px);
                margin: 0;
                font: font(700, 16px);
            }

            .clearAll {
                @include ClearAllButton;
            }
        }

        .filterBody {
            @include apply-styles(flex, margin, 8px 0px, padding, 0px 16px);
            // margin: 8px 0px;
            // padding: padding(0px, 16px, 0px, 12px);

            .filterOption {
                label {
                    @include apply-styles(flex, display, flex, align-items, center, font, 600 12 $primary_font, cursor, pointer);

                    input[type='checkbox'] {
                        @include apply-styles(flex, margin-right, 8px, width, 18px, height, 18px);
                        // margin-right: 8px;
                    }
                }
            }
        }
    }
}


.column2 {
    @include apply-styles(flex, padding, 24px 16px 24px 24px, height, 100vh, width, 77%, position, fixed, right, 12px);
    // padding: 24px 16px 24px 24px;
    overflow-y: auto;

    @media (max-width: 1100px) {
        @include apply-styles(flex, padding, 0px 10px 12px 10px, overflow-y, auto, width, 100%, position, relative, right, 0, margin-top, 120px);
        // padding: 0px 10px 12px 12px;
        // margin-top: 120px;
    }
}

.displayFlex {
    @include apply-styles(flex, display, flex, align-items, center);
    gap: gap(12);
}

.justifyContentSpaceBetween {
    @include apply-styles(flex, justify-content, space-between);
}

.icon {
    @include apply-styles(flex, display, flex, align-items, center, cursor, pointer, justify-content, flex-end);
}

.crossclick {
    img {
        @include apply-styles(flex, width, 24px, height, 24px);
    }
}

.noPadding {
    @include apply-styles(flex, padding, 0px !important);
    // padding: 0px !important;
}

.width185 {
    @include apply-styles(flex, width, 80%, padding-right, 16px);
    // padding-right: 16px;

    @media (max-width: 1100px) {
        @include apply-styles(flex, width, $SearchWidth);
    }
}


.query-button {
    background: #EC6608 0% 0% no-repeat padding-box;
    border-radius: 5px;
    color: #fff;
    padding: 10px 20px;
    border: none;
    font-size: 16px;
    transition: background-color 0.3s;
    height: 44px;
    width: 100%;

    &:hover {
        box-shadow: var(--mdc-protected-button-hover-container-elevation, 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12));
    }
}

.floatdiv.floatingedit {
    @include apply-styles(flex, position, fixed, bottom, 60px, right, 42px, z-index, 1000, border-radius, 50%, cursor, pointer);
}

.floatdiv.floatingedit .imgScss {
    @include apply-styles(flex, height, 72px);
}

@media (max-width: 767px) {
    .floatdiv.floatingedit {
        @include apply-styles(flex, position, fixed, bottom, 124px, right, 10px, z-index, 1000, border-radius, 50%);
    }

    .floatdiv.floatingedit .imgScss {
        @include apply-styles(flex, height, 60px, width, 60px);
    }
}

.floatdiv.scrollHide {
    display: none;
}


.calendarImage {
    background: #fff url(../../../../../assets/images/calendar.png) no-repeat 97% center;
}

//////////// Advance Date Range Calendar Selection UI

.advanceCalendar {
    @include apply-styles(flex, box-shadow, none!important, display, flex !important, height, auto);

    .dateRange {
        @include apply-styles(flex, display, flex, justify-content, space-between);

        .cal {
            @include apply-styles(flex, width, 24px, height, 24px);
        }
    }

    .leftSection {
        @include apply-styles(flex, color, #000000 !important, height, 48px, background-color, #fff, font, font(600, 13px), display, flex, align-items, center, padding, 0px 12px, cursor, pointer);
        // padding: 0px 12px;

        @media screen and (min-width: 576px) and (max-width: 1100px) {
            font: font(500, 13px);
            @include apply-styles(flex, display, flex, padding, 0px 0px 0px 0px);
            justify-content: center;
            padding: 0;
            align-items: center;
            
        }

        @media (max-width: 575px) {
            font: font(500, 11px);
            padding: 0;
        }
    }

    .active {
        border: 1px solid #EC6608 !important;
        border-radius: 12px !important;
        color: #EC6608 !important;
        display: flex;
        align-items: center;
        padding: 0px 12px;
        cursor: pointer;
        font: font(800, 13px);

        @media screen and (min-width: 576px) and (max-width: 1024px) {
            font: font(700, 13px);
        }

        @media (max-width: 575px) {
            font: font(800, 11px);
        }

        .mdc-button .mdc-button__label {
            padding: 12px !important;
        }
    }
}


.cursorPointer {
    cursor: pointer;
}


.offset {
    @include apply-styles(flex, margin, 1.25rem);
    // margin: 1.25rem;

    @media (max-width: 1100px) {
        @include apply-styles(flex, margin, 16px 12px);
        // margin: 16px 12px;
    }

    .dateRangeHeader {
        @include apply-styles(flex, display, flex);
        justify-content: space-between, ;
        align-items: baseline;
        font: font(700, 18px);

        @media (max-width: 1100px) {
            @include apply-styles(flex, display, flex);
            align-items: self-start;
            gap: gap(12);
        }

        h4 {
            @include apply-styles(flex, color, #111111);
            font: font(700, 16px);

            @media (max-width: 1100px) {
                @include apply-styles(flex, display, flex, letter-spacing, 0);
                font: font(700, 13px);
                align-items: center;
            }

            .AlertInfo {
                @include apply-styles(flex, width, 32px, height, 32px);
            }
        }

        span {
            .closeCalnedarIcon {
                cursor: pointer;

                @media (max-width: 1100px) {
                    @include apply-styles(flex, display, flex);
                    cursor: pointer;
                }
            }
        }
    }

    .dateRangeMessage {
        @include apply-styles(flex, width, 100%, display, flex, padding, 16px 0px, background-color, #ffe9e9, margin, 16px 0px, border-radius, 12px);
        // padding: 16px 0px;
        justify-content: center;

        @media (max-width: 1100px) {
            @include apply-styles(flex, padding, 12px);
            // padding: 12px;
            text-align: center;
            font: font(700, 10px);
        }
    }

    .dateRangeVisible {
        @include apply-styles(flex, color, #000000);
        font: font(700, 13px);
    }

    .dateRangeSelectMessage{
        @include apply-styles(flex, display, flex, align-items, center, background, #ffe9e9, padding, 12px, border-radius, 12px, margin-top, 16px);
        gap:gap(8);
        font:font(700, 13px);

        .AlertInfo {
            @include apply-styles(flex, width, 32px, height, 32px);
        }
    }

    .calendarSubmitBtn{
        @include apply-styles(flex, width, 100%, display, flex);
        justify-content:center;

        .width32{
            @include apply-styles(flex, width, 32%);

            @media (max-width:575px){
                @include apply-styles(flex, width, 100%);
            }
        }
    }
}

.wrapper {
    @include apply-styles(flex, display, flex);
    justify-content: center;
    gap: gap(16);

    @media (max-width: 1100px) {
        @include apply-styles(flex, display, flex, margin, margin(12px) !important);
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        gap: gap(10);
        margin: 20px !important;

        .calendarLabelFilter {
            @include apply-styles(flex, height, fit-content, display, flex, padding, 0, margin, 0, box-shadow, none);
            // padding: 0;
            // margin: 0;
            justify-content: space-between;
            min-width: calc(100% - 10%);
            gap: gap(32);
            align-items: center;
            flex-direction: row;
        }
    }

    .borderLine {
        @include apply-styles(flex, display, flex);
        border-left: 1px solid #dad7d7;
        border-radius: 12px;
    }
}

.presets {
    @include apply-styles(flex, width, 240px, text-align, left);

    button {
        @include apply-styles(flex, justify-content, start);
    }
}

.calendar {
    @include apply-styles(flex, width, 350px !important, padding, 0px 0px 16px 0px, box-shadow, none);
    // padding: 0px 0px 16px 0px;

    @media (max-width: 1100px) {
        @include apply-styles(flex, width, 314px !important, padding, 0px !important);
        // padding: 0px !important;
    }

    .calDetails {
        @include apply-styles(flex, height, auto, border, 1px solid #b7b7b4, border-radius, 16px);
    }
}

.editPopUpDialog {
    @include apply-styles(flex, padding, 12px 12px 24px 12px);
    // padding: 12px 12px 24px 12px;

    .editHeader {
        @include apply-styles(flex, display, flex, justify-content, space-between, align-items, center);

        .titlecase {
            @include apply-styles(flex, margin-bottom, 0);
            // margin-bottom: 0;
            font: font(700, 14px);
        }

        .clearAndClose {
            @include apply-styles(flex, display, flex);
            font: font(700, 13px);
            gap: gap(20);

            .clearAll {
                @include ClearAllButton;
            }
        }

        .closeCalnedarIcon {
            cursor: pointer;

            @media (max-width: 1100px) {
                @include apply-styles(flex, display, flex);
                cursor: pointer;
            }
        }
    }

    .matDivider {
        @include matDivider;
    }
}


//Filter Label 

.mat-mdc-checkbox label {
    @media(max-width:1100px) {
        font: font(700, 12px);
    }
}



.infoDiv {
    @include apply-styles(flex, width, 100%, background, #fff, display, flex, justify-content, space-between, margin-bottom, 12px, border-radius, 12px);
    // margin-bottom: 12px;

    @media (max-width: 400px) {
        @include apply-styles(flex, flex-direction, column);
    }

    .infoSection {
        @include apply-styles(flex, padding, 12px, display, flex, align-items, center);
        // padding: 12px;
        gap: gap(12);

        span {
            font: font(500, 12px);

            @media (max-width: 575px) {
                font: font(700, 12px);
            }

            img {
                @include apply-styles(flex, width, 18px, height, 18px);
            }
        }
    }

    .infoSection:last-child {
        span:last-child {
            font: font(700, 11.2px);

            @media (min-width: 576px) and (max-width: 1100px) {
                font: font(500, 12px);
            }
        }
    }
}